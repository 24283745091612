<template>
	<div>
		<b-row>
			<b-col lg="6" md="6" sm="6" class="heading-title">
				<h4>Venue Setting</h4>
				<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item active>Venue Setting</b-breadcrumb-item>
				</b-breadcrumb>
			</b-col>
			<b-col lg="6" md="6" sm="6" class="btns-header"> </b-col>
		</b-row>
		<br>
		<b-row>
			<b-col md="8">

			</b-col>
			<b-col md="4">
				
			</b-col>
		</b-row>
		<div class="tscroll11" >
			<table class="table scroll-table-customs table-bordered" >
				<thead class="table-stickys" ref="headRef">
					<tr>
						
						<th   class="header  text-left sticky-corner">Title</th>
						<th  @click="venueEditClick(v.id)" class="cursor-pointer header col-md-custom text-center"><b-icon icon="pencil"></b-icon> 
							<span ></span><br>
						</th>
						
					</tr>
					
				</thead>
				<tbody> 
					<tr>
                       <!--  <td rowspan="2"  class="turn-color">  <div class="turn-280">Venue Pricing</div></td>  -->
						<td class="sticky-column" >Auto Generate Invoice</td>
						<td  class="text-left">
							<input type="checkbox">
							
							</td>
					</tr>
					<tr>
                       <!--  <td rowspan="2"  class="turn-color">  <div class="turn-280">Venue Pricing</div></td>  -->
						<td class="sticky-column" >VB Percentage</td>
						<td  class="text-center">
							<div class="flex">
								<div>
									{{ results.Vbpercentage ? results.Vbpercentage+'%' : '0%' }}
                                  
								</div>
								<div :id="`popover-reactive19-1`">
									<i class="mdi mdi-pencil cursor-pointer"  ></i>
								</div>
							</div>

							<b-popover 
							:target="`popover-reactive19-1`"
							triggers="click"
							placement="top"
							no-fade
							>
								<template #title>
									<b-button @click="onClose3" class="close" aria-label="Close">
										<span class="d-inline-block" aria-hidden="true">&times;</span>
									</b-button>
									VB Percentage
								</template>
								
								<div> 
									<input type="text"  id="Vbpercentage" class="form-control venue-custom-input"  placeholder="Enter VB Amount Percentage" :value="results.Vbpercentage">
							</div>
								
								<div class="mt-3">
									
								<b-button @click="onClose3" size="sm" variant="danger" class="mr-2">Cancel</b-button>
									<b-button  size="sm" variant="primary"  @click="updateValue($event, 'Vbpercentage')">
									<b-spinner small v-if="eveningsave"></b-spinner>  Save</b-button>
								</div>
							</b-popover>
							</td>
					</tr>
					<tr>
                       <!--  <td rowspan="2"  class="turn-color">  <div class="turn-280">Venue Pricing</div></td>  -->
						<td class="sticky-column" >TDS Percentage</td>
						<td  class="text-center">
							<div class="flex">
								<div>
                                    {{ results.tdsPercentage ? results.tdsPercentage+'%' : '0%' }}
								</div>
								<div :id="`popover-reactive20-1`">
									<i class="mdi mdi-pencil cursor-pointer"  ></i>
								</div>
							</div>

							<b-popover 
							:target="`popover-reactive20-1`"
							triggers="click"
							placement="top"
							no-fade
							>
								<template #title>
									<b-button @click="onClose3" class="close" aria-label="Close">
										<span class="d-inline-block" aria-hidden="true">&times;</span>
									</b-button>
									TDS Percentage
								</template>
								
								<div> 
									<input type="text"  id="tdsPercentage" class="form-control venue-custom-input"  placeholder="Enter TDS Amount Percentage" :value="results.tdsPercentage">
							</div>
								
								<div class="mt-3">
									
								<b-button @click="onClose3" size="sm" variant="danger" class="mr-2">Cancel</b-button>
									<b-button  size="sm" variant="primary"  @click="updateValue($event, 'tdsPercentage')">
									<b-spinner small v-if="eveningsave"></b-spinner>  Save</b-button>
								</div>
							</b-popover>
							</td>
					</tr>
					<tr>
                       <!--  <td rowspan="2"  class="turn-color">  <div class="turn-280">Venue Pricing</div></td>  -->
						<td class="sticky-column" >Booking Refernce Number</td>
						<td  class="text-center">
							<div class="flex">
								<div>
                                    {{ results.refernce_number }}
								</div>
								<div :id="`popover-reactive14-1`">
									<i class="mdi mdi-pencil cursor-pointer"  ></i>
								</div>
							</div>

							<b-popover 
							:target="`popover-reactive14-1`"
							triggers="click"
							placement="top"
							no-fade
							>
								<template #title>
									<b-button @click="onClose3" class="close" aria-label="Close">
										<span class="d-inline-block" aria-hidden="true">&times;</span>
									</b-button>
									VB Invoice Number
								</template>
								
								<div> 
									<input type="text"  id="refernce_number" class="form-control venue-custom-input"  placeholder="Enter Booking Refernce Number" :value="results.refernce_number" >
							</div>
								
								<div class="mt-3">
									
								<b-button @click="onClose3" size="sm" variant="danger" class="mr-2">Cancel</b-button>
									<b-button  size="sm" variant="primary"  @click="updateValue($event, 'refernce_number')">
									<b-spinner small v-if="eveningsave"></b-spinner>  Save</b-button>
								</div>
							</b-popover>
							</td>
					</tr>
					<tr>
                       <!--  <td rowspan="2"  class="turn-color">  <div class="turn-280">Venue Pricing</div></td>  -->
						<td class="sticky-column" >VB Invoice Number</td>
						<td  class="text-center">
							<div class="flex">
								<div>
                                    {{ results.Invoice_Prefix }}
								</div>
								<div :id="`popover-reactive13-1`">
									<i class="mdi mdi-pencil cursor-pointer"  ></i>
								</div>
							</div>

							<b-popover 
							:target="`popover-reactive13-1`"
							triggers="click"
							placement="top"
							no-fade
							>
								<template #title>
									<b-button @click="onClose3" class="close" aria-label="Close">
										<span class="d-inline-block" aria-hidden="true">&times;</span>
									</b-button>
									VB Invoice Number
								</template>
								
								<div> 
									<input type="text"  id="Invoice_Prefix" class="form-control venue-custom-input"  placeholder="Enter VB Invoice Prefix" :value="results.Invoice_Prefix">
							</div>
								
								<div class="mt-3">
									
								<b-button @click="onClose3" size="sm" variant="danger" class="mr-2">Cancel</b-button>
									<b-button  size="sm" variant="primary"  @click="updateValue($event, 'Invoice_Prefix')">
									<b-spinner small v-if="eveningsave"></b-spinner>  Save</b-button>
								</div>
							</b-popover>
							</td>
					</tr>
                    
                    
                    <tr>
                       <!--  <td rowspan="2"  class="turn-color">  <div class="turn-280">Venue Pricing</div></td>  -->
						<td class="sticky-column" >Vendor ID</td>
						<td  class="text-center">
							<div class="flex">
								<div>
                                    {{ results.Vendor_id }}
								</div>
								<div :id="`popover-reactive17-1`">
									<i class="mdi mdi-pencil cursor-pointer"  ></i>
								</div>
							</div>

							<b-popover 
							:target="`popover-reactive17-1`"
							triggers="click"
							placement="top"
							no-fade
							>
								<template #title>
									<b-button @click="onClose3" class="close" aria-label="Close">
										<span class="d-inline-block" aria-hidden="true">&times;</span>
									</b-button>
									Vendor ID
								</template>
								
								<div> 
									<input type="text"  id="Vendor_id" class="form-control venue-custom-input"  placeholder="Enter Vendor ID" :value="results.Vendor_id">
							</div>
								
								<div class="mt-3">
									
								<b-button @click="onClose3" size="sm" variant="danger" class="mr-2">Cancel</b-button>
									<b-button  size="sm" variant="primary"  @click="updateValue($event, 'Vendor_id')">
									<b-spinner small v-if="eveningsave"></b-spinner>  Save</b-button>
								</div>
							</b-popover>
							</td>
					</tr>
                    <tr>
                       <!--  <td rowspan="2"  class="turn-color">  <div class="turn-280">Venue Pricing</div></td>  -->
						<td class="sticky-column" >Customer ID</td>
						<td  class="text-center">
							<div class="flex">
								<div>
                                    {{ results.Customer_id }}
								</div>
								<div :id="`popover-reactive15-1`">
									<i class="mdi mdi-pencil cursor-pointer"  ></i>
								</div>
							</div>

							<b-popover 
							:target="`popover-reactive15-1`"
							triggers="click"
							placement="top"
							no-fade
							>
								<template #title>
									<b-button @click="onClose3" class="close" aria-label="Close">
										<span class="d-inline-block" aria-hidden="true">&times;</span>
									</b-button>
									Customer ID
								</template>
								
								<div> 
									<input type="text"  id="Customer_id" class="form-control venue-custom-input"  placeholder="Enter Customer ID" :value="results.Customer_id">
							</div>
								
								<div class="mt-3">
									
								<b-button @click="onClose3" size="sm" variant="danger" class="mr-2">Cancel</b-button>
									<b-button  size="sm" variant="primary"  @click="updateValue($event, 'Customer_id')">
									<b-spinner small v-if="eveningsave"></b-spinner>  Save</b-button>
								</div>
							</b-popover>
							</td>
					</tr>
                    <tr>
                       <!--  <td rowspan="2"  class="turn-color">  <div class="turn-280">Venue Pricing</div></td>  -->
						<td class="sticky-column" >Franchise ID</td>
						<td  class="text-center">
							<div class="flex">
								<div>
								{{ results.franchise_id }}
								</div>
								<div :id="`popover-reactive16-1`">
									<i class="mdi mdi-pencil cursor-pointer"  ></i>
								</div>
							</div>

							<b-popover 
							:target="`popover-reactive16-1`"
							triggers="click"
							placement="top"
							no-fade
							>
								<template #title>
									<b-button @click="onClose3" class="close" aria-label="Close">
										<span class="d-inline-block" aria-hidden="true">&times;</span>
									</b-button>
									Franchise ID
								</template>
								
								<div> 
									<input type="text"  id="franchise_id" class="form-control venue-custom-input"  placeholder="Enter Franchise ID" :value="results.franchise_id" >
							</div>
								
								<div class="mt-3">
									
								<b-button @click="onClose3" size="sm" variant="danger" class="mr-2">Cancel</b-button>
									<b-button  size="sm" variant="primary"  @click="updateValue($event, 'franchise_id')">
									<b-spinner small v-if="eveningsave"></b-spinner>  Save</b-button>
								</div>
							</b-popover>
							</td>
					</tr>
					
                    <tr>
                       <!--  <td rowspan="2"  class="turn-color">  <div class="turn-280">Venue Pricing</div></td>  -->
						<td class="sticky-column" >Service Provider ID</td>
						<td  class="text-center">
							<div class="flex">
								<div>
                                    {{ results.service_provider_id }}
								</div>
								<div :id="`popover-reactive18`">
									<i class="mdi mdi-pencil cursor-pointer"  ></i>
								</div>
							</div>

							<b-popover 
							:target="`popover-reactive18`"
							triggers="click"
							placement="top"
							no-fade
							>
								<template #title>
									<b-button @click="onClose3" class="close" aria-label="Close">
										<span class="d-inline-block" aria-hidden="true">&times;</span>
									</b-button>
									Service Provider ID
								</template>
								
								<div> 
									<input type="text"  id="service_provider_id" class="form-control venue-custom-input"  placeholder="Enter Service Provider ID" :value="results.service_provider_id">
							</div>
								
								<div class="mt-3">
									
								<b-button @click="onClose3" size="sm" variant="danger" class="mr-2">Cancel</b-button>
									<b-button  size="sm" variant="primary"  @click="updateValue($event, 'service_provider_id')">
									<b-spinner small v-if="eveningsave"></b-spinner>  Save</b-button>
								</div>
							</b-popover>
							</td>
					</tr>
					<tr>
                       <!--  <td rowspan="2"  class="turn-color">  <div class="turn-280">Venue Pricing</div></td>  -->
						<td class="sticky-column" >Payment Days</td>
						<td  class="text-center">
							<div class="flex">
								<div>
                                    {{ results.pay_days }}
								</div>
								<div :id="`popover-reactive19`">
									<i class="mdi mdi-pencil cursor-pointer"  ></i>
								</div>
							</div>

							<b-popover 
							:target="`popover-reactive19`"
							triggers="click"
							placement="top"
							no-fade
							>
								<template #title>
									<b-button @click="onClose3" class="close" aria-label="Close">
										<span class="d-inline-block" aria-hidden="true">&times;</span>
									</b-button>
									Payment Days
								</template>
								
								<div> 
									<input type="text"  id="pay_days" class="form-control venue-custom-input"  placeholder="Enter Vb payment days" :value="results.pay_days">
							</div>
								
								<div class="mt-3">
									
								<b-button @click="onClose3" size="sm" variant="danger" class="mr-2">Cancel</b-button>
									<b-button  size="sm" variant="primary"  @click="updateValue($event, 'pay_days')">
									<b-spinner small v-if="eveningsave"></b-spinner>  Save</b-button>
								</div>
							</b-popover>
							</td>
					</tr>
					
					
					
				</tbody>
			</table>
		</div>
		
		
	</div>
</template>
<script>
	import axios from "axios";
	export default {
		data() {
			return {
				results:[],
				parent_selected:[],
				parent_option:[],
				loader_appy:false,
				tableShow:false,
				afterload:false,
				security:[],
				parent_id:'',
				parnt_id:'',
				EnableCalendar:false
			}
		},
		components:
		{
		},
		methods:
		{
			
			loadSetting:function()
			{
				axios.get('/admin/general_setting').then(response => {
					this.results = response.data;
					
				})
				
			},
			json_convert(attr)
			{
				const v=attr != null ? JSON.parse(attr) :false;
				return v;
			},
			/* parentVenueList:function() {
				axios.get("/api/parent_venue_listing").then((resp) => {
					if (resp.data.status_code == 200) {
						
						this.parent_selected=resp.data.parent_venue_details;
						
					}
				});
			}, */
			parentVenueList() {
				axios.get("/api/parent_venue_listing").then((resp) => {
					if (resp.data.status_code == 200) {
					if (resp.data.status) {
						for (var i = 0; i < resp.data.parent_venue_details.length; i++) {
						this.parent_option.push({
							value: resp.data.parent_venue_details[i].parent_venue_id,
							name: resp.data.parent_venue_details[i].venue_name,
						});
						}
					}
					}
				});
				},
			get_child_venue()
			{
				
				this.loader_appy=true;
				if (this.parent_id !== "")  {
					this.parnt_id=this.parent_id.value;
					axios
					.get("/api/load_child_venues?parent_venue_id="+this.parent_id.value)
					.then((resp) => {
						// if (resp.data.status_code == 200) {
						this.tableShow=true;
						this.results=resp.data;
						this.loader_appy=false;
						this.afterload=true;
						// }
					});
				}
				else
				{
					this.$root.$refs.app.showToast("danger", "Please select venue");
				}
			},
			updateValue(event, key) {
				
				const value_to_be = document.getElementById(key).value
			
				if(value_to_be!="" && value_to_be!=0 )
				{
					
				/* this.$swal({
					title: 'Are you sure?',
					text: "Are you sure want to Update this tab!",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, Update it!'
					}).then((result) => {
					if (result.value) {
						 */
						axios.post('/admin/update_general_setting',{value:value_to_be,key:key}).then(response => {
							response
							this.loadSetting();
							this.$root.$emit('bv::hide::popover')
							// this.results = response.data;
							/* this.$swal(
							'Update!',
							'Your file has been updated.',
							'success'
							) */
						})
						
				/*	}
				 }); */
			}
				/*  */
			}
			,updateValueCheckbox(event, id, key) {
				
				const value_to_be = event.target.value;
				
				if(value_to_be!="" && value_to_be!=0 )
				{
					
				this.$swal({
					title: 'Are you sure?',
					text: "Are you sure want to Update this tab!",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, Update it!'
					}).then((result) => {
					if (result.value) {
						
						axios.post('/api/update_venue_setting',{value:event.target.checked,vid:id,key:key}).then(response => {
							response
							// this.results = response.data;
							this.$swal(
							'Update!',
							'Your file has been updated.',
							'success'
							)
						})
						
					}
				});
			}
				/*  */
			},
			
			updateValues(event,status, id) {
				
				this.$swal({
					title: 'Are you sure?',
					text: "Are you sure want to Update this Status!",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, Update it!'
					}).then((result) => {
					if (result.value) {
						axios.post('/api/update_venue_status',{value:status==0 ? 1 : 0,vid:id}).then(response => {
							response
							// this.results = response.data;
							this.$swal(
							'Update!',
							'Your Status has been updated.',
							'success'
							)
						})
						
					}
				});
				/*  */
			},
			onClose3()
			{
				this.$root.$emit('bv::hide::popover')
			},
			SelctCalendarLocate()
			{
				this.$router.push("/new_calender/");
// /new_calender
			}
		
			
		},
		mounted()
		{
			//this.parentVenueList();
			this.loadSetting();
		
			document.documentElement.scrollTop = 0;

		},
	
	}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style >
	
   .scrollDown
	{
	position: fixed;
	top: 62px;
	background-image: linear-gradient(242deg, #A44BF3 0%, #499CE8 100%) !important;
	color:white;
	/* width: 100%; */
	}  
	.scrollDown th {
		width: 18.9rem;
	}
	
	/* .custom-side
	{
	width:500px !important;
	}
	.venus-side
	{
	right:0;
	top:10%;
	position: fixed;
	
	}
	.turn-280
	{
	transform: rotate(270deg);
	text-align: center;
	font-size: 10px;
	line-height: 25px;
	min-height: 25px;
	height: 25px;
	width: 40%;
	
	}
	.small_span {
	width: 5% !important;
	padding: 8px 0 3px 7px;
	}
	
	tr td:first-child.turn-color
	{
	
	font-weight: bold;
	background-image: linear-gradient(242deg, #499CE8 0%, #A44BF3 100%) !important;
	color:white;
	} */
	
	/* table.tableFixHead > thead > tr > th{
	border:1px solid gray;
	}
	table.tableFixHead > tbody > tr > td{
	border:1px solid gray;
	} */
	
	/* input[type="checkbox"]
	{
	padding:2px;
	border:1px solid gray !important;
	} */
	/* input[type="text"],  select {
	border: none;
	outline: none;
	
	} */
	/* .cursor-pointer
	{
	cursor: pointer;
	}
	.float-right
	{
	float:right;
	}
	.venue_delete
	{
	position: absolute;
	right: -15px;
	top: -50px;
	z-index: 999;
	}
	.inactive
	{
	background-color: lightgray;
	color: white;
	}
	.custom-button
	{
	position:absolute;
	right:-10px;
	top:-18%;
	padding:10px;
	
	}
	
	*/
	
	
	
	
	/* Just common table stuff. Really. */
	
	/* .venue-custom-input
	{
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 1px solid #dfdcdc;
	border-radius: unset;
	padding:0;
	}
	.venue-custom-input:focus
	{
	border-bottom: 1px solid #DD1BFB;
	}
	*/
	/* new  */
	
	.turn-color
	{
	max-width: 36px;
	background-image: linear-gradient(242deg, #A44BF3 0%, #499CE8 100%) !important;
	color:white;
	width: 0;
	}
	.turn-280
	{
	transform: rotate(270deg);
	white-space: nowrap;
	margin-top:80px;
	}
	.sticky-column
	{
		width: 25%;
	}
	.col-md-custom
	{
	/* max-width: 171px;
    width: 172px; */
	max-width: 30%;
    width: 21%;
	}
	.input-padding
	{
	
	padding: 0 !important;
	}
	
	
	.tscrolls {
	width: 100%;
	height: 450px;
	overflow-y: scroll;
	overflow-x: scroll;
	margin-bottom: 10px;
	}
	
	
	.tscroll .scroll-table-custom td:first-child, .scroll-table-custom th:first-child {
	position: sticky;
	left: 0;
	background-image: linear-gradient(242deg, #A44BF3 0%, #499CE8 100%) !important;
	color:white;
	width: 20px;
	}
	
	.tscroll td, .tscroll th {
	border-bottom: dashed #888 1px;
	min-width:300px;
	}
	
	.scroll-table-custom  th , .scroll-table-custom  td  {
		
  position: sticky;
  position: -webkit-sticky;
  top: 0;
	}
	.table-stickys
	{
	background-image: linear-gradient(242deg, #A44BF3 0%, #499CE8 100%) !important;
	color:white;
	/* width: 100%;  */
	
	box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
	
	}
	thead tr.publish_header
	{
		background-color: white;
	color:rgb(214, 24, 24);
	}
	
	
	.table-sticky tbody td.sticky-column {
	z-index: 222;
	}
	
	.table-sticky thead th:first-child {
	z-index: 1;
	}
	input:focus>.input-group-append {
  background-color: yellow !important;
  display: block;
}




</style>					